import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Concert from "../components/Concert";
import Header from "../components/Header";

function Detail() {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [status, setStatus] = useState("");
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");
  const invitationID = searchParams.get("InvitationID");

  useEffect(() => {
    fetch(
      `https://api.sl2.nl/api/relatiebeheer/MyEventSuggestions/GetEventSuggestionsList?InvitationID=${invitationID}`
    )
      .then((response) => {
        setStatus(response.status);
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [invitationID]);

  if (status == 400) {
    return (
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Deze uitnodiging is niet geldig.
      </h2>
    );
  }

  if (!data) {
    return <div></div>;
  }
  const category = data.EventCategorieList.find((c) => c.$id == id);

  if (!category) {
    return (
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Deze uitnodiging is niet geldig.
      </h2>
    );
  }

  const event = eventId
    ? category.EventList.find((e) => e.$id == eventId)
    : null;

  if (eventId && !event) {
    return (
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Deze uitnodiging is niet geldig.
      </h2>
    );
  }

  const handleSignupClick = () => {
    if (event && event.EventSignUpURL) {
      window.location.href = event.EventSignUpURL;
    }
  };
  console.log(event);
  return (
    <div
      style={{
        color:"#5B5B5B",
      }}
    >
      <Header data={data} />
      <div className="container">
        <div
          className="concert__title-container detail-title"
          style={{
            background: `#${data.HTMLStyling.Color2}`,
          }}
        >
          <div
            className="concert__title-date"
            style={{
              color:"#5B5B5B",
            }}
          >
            {event?.HeaderText}
          </div>
        </div>
        <div id="detail-container" className="detail__container">
          <div className="detail__container-text">
            <div className="detail__text-title">
              <h2>{event.Name}</h2>
            </div>
            <div className="detail__text-details">
              <div className="header__details">
                {event.DateDescriptionDate?.trim() && (
                  <div className="header__details-container">
                    <div className="header__details-label">Datum:</div>
                    <div className="header__details-value">
                      {event.DateDescriptionDate}
                    </div>
                  </div>
                )}
                {event.Location.Name?.trim() && (
                  <div className="header__details-container">
                    <div className="header__details-label">Locatie:</div>
                    <div className="header__details-value">
                      {event.Location.Name}
                    </div>
                  </div>
                )}
                {event.DateDescriptionTime?.trim() && (
                  <div className="header__details-container">
                    <div className="header__details-label">Aanvang:</div>
                    <div className="header__details-value">
                      {event.DateDescriptionTime}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="detail__text-description">
              {event.EventMemoList?.map((memo) => (
                <>
                  <h3>{memo.Description}</h3>
                  <span style={{ whiteSpace: "pre-wrap" }}>{memo.Memo}</span>
                </>
              ))}
            </div>
            <div
              className="detail__text-btn"
              onClick={handleSignupClick}
              style={{
                background: `#${data.HTMLStyling.Color3}`,
                color: `#${data.HTMLStyling.TextColor3}`,
              }}
            >
              {event?.ButtonInvitationText}
            </div>
          </div>
          <div className="detail__container-image">
            <img className="detail__image" src={event.ImageURL} alt="Banner" />
          </div>
        </div>
        <div className="concert__container">
          <div className="concert__title-container detail__container-bottom">
            <div
              className="concert__title-date"
              style={{
                background: `#${data.HTMLStyling.Color2}`,
                color:"#5B5B5B",
                width: "100%",
              }}
            >
              {category.ShortName}
            </div>
          </div>
          {category.EventList?.map((eventCategory) => (
            <Concert
              key={eventCategory.$id}
              event={eventCategory}
              id={category.$id}
              textColor={
               '#5B5B5B'
              }
              backgroundColor={data.HTMLStyling.Color2}
              buttonBackground={data.HTMLStyling.Color3}
              buttonColor={data.HTMLStyling.TextColor3}
            />
          ))}
        </div>
        <Link
          style={{
            color:"#5B5B5B",
          }}
          className="detail__link-home"
          to={`/?InvitationID=${invitationID}`}
        >
          Toon alle evenementen
        </Link>
      </div>
    </div>
  );
}
export default Detail;
