import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Tile from '../components/Tile';
import Header from '../components/Header';

function Home() {
  const [data, setData] = useState('');
  const [status, setStatus] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const invitationID = searchParams.get("InvitationID");
  useEffect(() => {
    fetch(`https://api.sl2.nl/api/relatiebeheer/MyEventSuggestions/GetEventSuggestionsList?InvitationID=${invitationID}`)
      .then(response => {
        setStatus(response.status)
        return response.json()
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  if (status == 400){
    return <h2 style={{
      textAlign: 'center'
    }}>Deze uitnodiging is niet geldig.</h2>;
  }
  if (!invitationID){
    return <h2 style={{
      textAlign: 'center'
    }}>Deze uitnodiging is niet geldig.</h2>;
  }
  if (!data){
    return <div></div>;
  }

console.log(data);
  return (
    <div style={{
      color: "#5B5B5B",
    }}>
      <div className='list__container'>
 <Link to={`/?InvitationID=${invitationID}`}>
                    {data.HTMLStyling && (
                    <img
                        className="header__logo"
                        src={data.HTMLStyling.LogoURL}
                        alt="Logo"
                    />
                    )}
                </Link>
      </div>
      
      <Header data={data}/>
      <div className='list__container'>
        <div className='list__title-container'>
          <h2>
            {data?.Invitation?.HeaderText}
          </h2>
          <h5>
           {data?.Invitation?.SubText}
          </h5>
        </div>
        <div className="tiles-container">
          {data.EventCategorieList?.map((tile) => (
            <Tile
              key={tile.$id}
              id={tile.$id}
              tile={tile}
              textColor={'#5B5B5B'}
              backgroundColor={data.HTMLStyling.Color2}
              buttonBackground={data.HTMLStyling.Color3}
              buttonColor={data.HTMLStyling.TextColor3}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
