import React, { useState } from 'react';
import Popup from './Popup';
import Tileimg from '../assets/img/tile.png';

function Tile({ tile, backgroundColor, textColor, buttonBackground, buttonColor }) {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  // console.log(tile.ImageURL)

  if (!tile) {
    return <div></div>;
  }
  return (
    <div className='tile__container'>
      <img className="tile__image" src={tile.ImageURL} alt={tile.ShortName} onClick={togglePopup} />
      <div className="tile__content">
        <h3 className="tile__title">{tile.ShortName}</h3>
        <span className="tile__subtitle" onClick={togglePopup} style={{
              background: `#${buttonBackground}`,
              color: `#${buttonColor}`,
              cursor: "pointer"
            }}>{tile.EventList.length} {tile.EventList.length === 1 ? "optie" : "opties"}</span>
      </div>
      {showPopup && (
        <Popup
          tile={tile}
          onClose={togglePopup}
          id={tile.$id}
          textColor={textColor}
          backgroundColor={backgroundColor}
          buttonBackground={buttonBackground}
          buttonColor={buttonColor}
        />
      )}
    </div>
  );
}

export default Tile;
