import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

function Header({ data }) {
  const [searchParams] = useSearchParams();
  const invitationID = searchParams.get("InvitationID");
  const location = useLocation();

  if (!data) {
    return <div></div>;
  }
  const isDetailPage = location.pathname.includes("/details/");

  return (
    <div className={`header ${!isDetailPage ? "header-home" : ""}`}>
      <div
        className="header__background"
        style={{
          backgroundColor: `#${data.HTMLStyling.Color1}`,
        }}
      ></div>
      <div
        className="header__container"
        style={{
          color: data?.HTMLStyling?.TextColor2
            ? `#${data.HTMLStyling.TextColor2}`
            : "#1E1E1E",
        }}
      >
        <div className="header__content">
          <div className="header__title-container">
            <h1
              className="header__title"
              style={{
                color: `#${data.HTMLStyling.TextColor1}`,
              }}
            >
              Uitnodiging
            </h1>
            {!isDetailPage && (
              <p className="header__subtitle">
                Deze uitnodiging is speciaal voor u en wordt u aangeboden door{" "}
                {data.Invitation.InvitedByCompany.Name}.
              </p>
            )}
          </div>
          <div className="header__divider"></div>
          <div className="header__details">
            <div className="header__details-container">
              <div className="header__details-label">Uw bedrijfsnaam:</div>
              <div className="header__details-value">
                {data.Invitation.Customer.Company.Name}
              </div>
            </div>
            <div className="header__details-container">
              <div className="header__details-label">Uw naam:</div>
              <div className="header__details-value">
                {data.Invitation.Customer.Contactperson.FullName}
              </div>
            </div>
            <div className="header__details-container">
              <div className="header__details-label">Uw contactpersoon:</div>
              <div className="header__details-value">
                {data.Invitation.InvitedByAccountmanager.FullName}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
