import React from "react";
import Close from "../assets/img/close.svg";
import Concert from "./Concert";

function Popup(props) {
  const handleClosePopup = (event) => {
    if (event.target === event.currentTarget) {
      props.onClose();
    }
  };
  return (
    <div className="popup" onClick={handleClosePopup}>
      <div className="popup__container">
        <div className="popup__head-container">
          <img className="popup__logo" src={props.tile.ImageURL} alt="Logo" />
          <div className="popup__content">
            <div className="popup__title-container">
              <h1 className="popup__title">{props.tile.ShortName}</h1>
              <p className="popup__subtitle">{props.tile.Description}</p>
            </div>
          </div>
          <button className="popup__close" onClick={props.onClose}>
            <img className="popup__close-svg" src={Close} alt="close" />
          </button>
        </div>

        <div
          className="concert__title-container"
          style={{
            background: `#${props.backgroundColor}`,
          }}
        >
          <div
            className="concert__title-date"
            style={{
              color: `#5B5B5B`,
            }}
          >
            Datum
          </div>
          <div
            className="concert__title-events"
            style={{
              color: `#5B5B5B`,
            }}
          >
            Keuze
          </div>
        </div>
        <div className="concert__container">
          {props.tile.EventList.map((event) => (
            <Concert
              key={event.$id}
              event={event}
              id={props.tile.$id}
              textColor={'#5B5B5B'}
              backgroundColor={props.backgroundColor}
              buttonColor={props.buttonColor}
              buttonBackground={props.buttonBackground}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Popup;
