import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Concert = (props) => {
  const [searchParams] = useSearchParams();
  const invitationID = searchParams.get("InvitationID");
  const searchParamsDetail = new URLSearchParams({
    eventId: props.event && props.event.$id,
    InvitationID: invitationID,
  });
  const handleButtonClick = () => {
    if (window.innerWidth < 1200) {
      scroll.scrollTo("detail-container", {
        smooth: true,
        duration: 800,
        offset: -100,
      });
    }
  };

  const detailsUrl = `${props.id}?${searchParamsDetail.toString()}`;

//   const dateString = props.event && props.event.StartDate;
//   const daysOfWeek = [
//     "Zondag",
//     "Maandag",
//     "Dinsdag",
//     "Woensdag",
//     "Donderdag",
//     "Vrijdag",
//     "Zaterdag",
//   ];
  // const dateObj = new Date(dateString);
  // const dayOfWeek = daysOfWeek[dateObj.getDay()];
  return (
    <div className="concert__item-container">
      <div className="concert__date">
        <div className="concert__date-wrapper">
          <div
            className="concert__date-location"
            style={{
              color: "#5B5B5B",
            }}
          >
            {props.event && props.event.DateDescriptionDayOfWeek}
          </div>
          <div
            className="concert__date-date"
            style={{
              color:"#5B5B5B",
            }}
          >
            {props.event && props.event.DateDescriptionDate}
          </div>
          <div
            className="concert__date-time"
            style={{
              color:"#5B5B5B",
            }}
          >
            {props.event && props.event.DateDescriptionTime}
          </div>
        </div>
      </div>
      <div className="concert__events">
        <div className="concert__events-wrapper">
          <div
            className="concert__events-location"
            style={{
              color:"#5B5B5B",
            }}
          >
            {props.event && props.event.Name}
          </div>
          <div
            className="concert__events-date"
            style={{
              color: "#5B5B5B",
            }}
          >
            {props.event && props.event.Location.Name}
          </div>
        </div>
        <div className="concert__details">
          <Link
            to={`/details/${detailsUrl}`}
            onClick={handleButtonClick}
            className="concert__details-btn"
            style={{
              background: `#${props.buttonBackground}`,
              color: `#${props.buttonColor}`
            }}
          >
            <span>Details</span>
          </Link>
        </div>
      </div>
      <div className="concert__details-mobile">
        <Link
          to={`/details/${detailsUrl}`}
          onClick={handleButtonClick}
          className="concert__details-btn"
          style={{
            background: `#${props.backgroundColor}`,
          }}
        >
          <span>Details</span>
        </Link>
      </div>
    </div>
  );
};

export default Concert;
